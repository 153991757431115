<template>
  <component
    :is="component"
    v-bind="binding"
    :class="classes"
    :disabled="isDisabled || isLoading"
  >
    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0 scale-0"
      leave-active-class="transition"
      leave-to-class="opacity-0 scale-0"
    >
      <div
        v-show="isLoading"
        class="absolute inset-0 flex items-center justify-center"
      >
        <app-spinner :size="20" />
      </div>
    </transition>

    <span
      class="flex h-full items-center justify-center gap-2 text-center transition-opacity"
      :class="{ 'opacity-0': isLoading, '!gap-1': size === 'xs' }"
    >
      <app-icon v-if="icon" :icon="icon" :size="iconSize.get(size)" />
      <slot />
      <app-icon
        v-if="appendIcon"
        :icon="appendIcon"
        :size="iconSize.get(size)"
      />
    </span>
  </component>
</template>

<script lang="ts" setup>
import type { FormState } from "../../form/composables/form.hook";
import { FormStateKey } from "../../form/composables/form.hook";
import type {
  ButtonColor,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from "../button.model";

const NuxtLink = resolveComponent("nuxt-link");

const properties = withDefaults(defineProps<ButtonProps>(), {
  variant: "plain",
  color: undefined,
  icon: undefined,
  disabled: false,
  size: "default",
  to: undefined,
  type: "button",
  tag: undefined,
  href: undefined,
  appendIcon: undefined,
});

const component = computed(() => {
  if (properties.tag) return properties.tag;

  if (properties.href) return "a";

  return properties.to && !properties.disabled ? NuxtLink : "button";
});

const parentFormState = inject<FormState | undefined>(FormStateKey, {
  submitting: ref(false),
  readonly: ref(false),
});

const isLoading = computed(
  () => parentFormState?.submitting.value || properties.loading,
);

const isDisabled = computed(
  () => properties.disabled || parentFormState?.readonly.value,
);

const binding = computed(() => {
  return {
    to: properties.to,
    rel: properties.noFollow ? "nofollow" : undefined,
    type: component.value === "button" ? properties.type : undefined,
    href: properties.href,
    target: properties.href ? "_blank" : undefined,
  };
});

const iconSize = new Map<ButtonSize, number>([
  ["default", 20],
  ["lg", 24],
  ["small", 16],
  ["xs", 16],
]);

const definitions = new Map<
  | `${ButtonColor}.${ButtonVariant}`
  | ButtonColor
  | ButtonVariant
  | ButtonSize
  | "disabled"
  | "loading",
  string
>([
  // Size classes
  ["default", "h-[38px] text-base font-semibold px-4"],
  ["lg", "h-[48px] text-base font-semibold px-4"],
  ["small", "h-[28px] text-sm font-semibold px-2"],
  ["xs", "h-[20px] text-xs font-normal px-2"],

  // Variant classes
  ["plain", "active:shadow-none"],
  ["tonal", "!shadow-none"],
  ["outlined", "border border-b-0 shadow-relief-2"],
  ["link", "!p-0 !underline !shadow-none !h-auto"],

  // State classes
  [
    "disabled",
    "!bg-disabled !text-disabled !border-0 !shadow-none !cursor-not-allowed !ring-black/10 active:!scale-100",
  ],
  ["loading", "!cursor-emoji-[⏳] active:!scale-100"],

  // Accent color variants
  ["accent", "ring-accent-200"],
  ["accent.plain", "text-white bg-accent hover:bg-accent-400"],
  [
    "accent.tonal",
    "bg-accent-100 text-accent hover:bg-accent-100/75 active:bg-accent-200",
  ],
  [
    "accent.outlined",
    "border-accent shadow-accent text-accent hover:bg-accent-100 active:bg-accent-200 !active:scale-100",
  ],
  ["accent.link", "text-accent"],

  // Primary color variants
  ["primary", "ring-primary-200"],
  ["primary.plain", "text-white bg-primary-900 hover:bg-primary-700"],
  [
    "primary.tonal",
    "bg-primary-100 text-primary hover:bg-primary-100/75 active:bg-primary-200",
  ],
  [
    "primary.outlined",
    "border-primary shadow-primary text-primary hover:bg-primary-100 active:bg-primary-200 !active:scale-100",
  ],
  ["primary.link", "text-primary"],

  // Light color variants
  ["light", "ring-white/50"],
  ["light.plain", "text-black bg-white hover:bg-white/90"],
  ["light.tonal", "bg-white/10 text-white hover:bg-white/5 active:bg-white/20"],
  [
    "light.outlined",
    "border-white shadow-white text-white bg-black/10 hover:bg-white/5 active:bg-black/10",
  ],

  // Dark color variants
  ["dark", "ring-black/50"],
  ["dark.plain", "text-white bg-black hover:bg-black/90"],
  ["dark.tonal", "bg-gray-50 text-black hover:bg-gray-100 active:bg-gray-50"],
  [
    "dark.outlined",
    "border-black shadow-black text-black hover:bg-black/5 active:bg-black/10",
  ],

  // Danger color variants
  ["danger", "ring-error/50"],
  ["danger.plain", "text-white bg-error hover:bg-error/90"],
  [
    "danger.tonal",
    "bg-error-50 text-error-950 hover:bg-error-100 active:bg-error-50",
  ],
  [
    "danger.outlined",
    "border-error shadow-error text-error-950 hover:bg-error/5 active:bg-error/10",
  ],
]);

const appliedColor = computed<ButtonColor>(
  () =>
    properties.color ??
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((useAppConfig().theme as any)?.buttonDefaultColor as ButtonColor) ??
    "accent",
);

const classes = computed(() => {
  return [
    "relative",
    "rounded",
    "no-underline",
    "cursor-pointer",
    "shadow-relief",
    "inline-block",
    "relative",
    "outline-none",
    "focus-visible:ring",
    "transition",
    "no-underline",
    "active:scale-95",
    "not-italic",
    "font-sans",

    definitions.get(appliedColor.value),
    definitions.get(properties.variant),
    definitions.get(`${appliedColor.value}.${properties.variant}`),
    definitions.get(properties.size),

    isDisabled.value ? definitions.get("disabled") : "",
    isLoading.value ? definitions.get("loading") : "",
    properties.block ? "!block w-full" : "",
  ];
});
</script>
